














































































































import { Payment } from "@/models/payment.model";
import UserSelect from "@/components/admin/users/UserSelect.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import moment from "moment";
import PaymentsService from "@/services/PaymentsService";

const paymentsNamespace = namespace("Payments");

@Component({ components: { UserSelect } })
export default class PaymentDetail extends Vue {
  isLoading = false;
  selectedUser: string[] | string = [];

  @paymentsNamespace.Getter("current")
  payment!: Payment;

  @paymentsNamespace.Mutation("SET_CURRENT")
  setPayment!: (payment: Payment) => void;

  @paymentsNamespace.Action("fetchById")
  fetchById!: (id: string) => Promise<Payment>;

  @paymentsNamespace.Action("create")
  create!: (payment: Payment) => Promise<Payment>;

  @paymentsNamespace.Action("update")
  update!: (payment: Payment) => Promise<Payment>;

  @paymentsNamespace.Action("setPaid")
  setPaid!: (id: string) => Promise<Payment>;

  @paymentsNamespace.Action("setUnpaid")
  setUnpaid!: (id: string) => Promise<Payment>;

  get paymentId() {
    return this.payment.id;
  }

  get isCreate() {
    return !this.paymentId;
  }

  get locale() {
    return this.$i18n.locale;
  }

  get createdAt() {
    return moment(this.payment?.created_at).format("LLL") || "N/A";
  }

  get updatedAt() {
    return moment(this.payment?.updated_at).format("LLL") || "N/A";
  }

  get isPaid() {
    return this.payment?.is_paid || false;
  }

  get isPaidText() {
    return this.isPaid ? "Yes" : "No";
  }

  get hasMollieLink() {
    return (
      this.payment?.payment_method === "mollie" && this.payment?.transaction_id
    );
  }

  get mollieLink() {
    return `https://my.mollie.com/dashboard/payments/${this.payment?.transaction_id}`;
  }

  get redirectUrl() {
    return this.payment?.redirect_url;
  }

  async doSetPaid() {
    this.isLoading = true;
    try {
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to set this payment to paid`,
        {
          title: "Send",
          okVariant: "primary",
          centered: true,
        }
      );
      if (isConfirmed) {
        await this.setPaid(this.payment.id);
      }
    } finally {
      this.isLoading = false;
    }
    this.isLoading = false;
  }

  async doSetUnpaid() {
    this.isLoading = true;
    try {
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to set this payment to unpaid`,
        {
          title: "Send",
          okVariant: "primary",
          centered: true,
        }
      );
      if (isConfirmed) {
        await this.setUnpaid(this.payment.id);
      }
    } finally {
      this.isLoading = false;
    }
  }

  async doSendConfirmationEmail() {
    this.isLoading = true;
    try {
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to re-send the payment confirmation for this payment`,
        {
          title: "Send",
          okVariant: "primary",
          centered: true,
        }
      );
      if (isConfirmed) {
        await PaymentsService.sendConfirmation(this.payment.id);
      }
    } finally {
      this.isLoading = false;
    }
  }

  async save() {
    this.isLoading = true;
    try {
      this.payment.user_id = this.selectedUser as string;
      if (this.$route.params.id) {
        await this.update(this.payment);
      } else {
        await this.create(this.payment);
        this.$root.$bvToast.toast("Payment created!", {
          variant: "success",
        });
        this.$router.push({
          name: "payments-detail",
          params: { id: this.payment.id },
        });
      }
    } finally {
      this.isLoading = false;
    }
  }

  async created() {
    this.isLoading = true;
    if (this.$route.params.id) {
      await this.fetchById(this.$route.params.id);
      if (this.payment?.user_id) {
        this.selectedUser = [this.payment?.user_id];
      }
    } else {
      this.setPayment(new Payment());
    }
    this.isLoading = false;
  }
}
